import { columnsDataColumns } from "./variables/columnsData";
import tableDataColumns from "./variables/tableDataColumns.json";
import ProjectsTable from "./components/ProjectsTable";
import React, { useState, useEffect } from "react";

const Marketplace = () => {
  const [historyData, setHistoryData] = useState([]);
  const [totalLubang, setTotalLubang] = useState(0);
  const [totalRetakMelintang, setTotalRetakMelintang] = useState(0);
  const [totalRetakMemanjang, setTotalRetakMemanjang] = useState(0);
  const [totalRetakBuaya, setTotalRetakBuaya] = useState(0);

  const user = sessionStorage.getItem("name");

  const getQueryParam = (name) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  };

  const file = getQueryParam("file"); 
  console.log(file);
  const fetchHistoryData = async () => {
    try {
      const url = file
         `${process.env.REACT_APP_BACKEND_URL}/history`;
      console.log(url);
      const response = await fetch(url);
      const data = await response.json();
      setHistoryData(data);
    } catch (error) {
      console.error("Error fetching history data:", error);
    }
  };

  useEffect(() => {
    const newTotalLubang = historyData.reduce(
      (total, item) => total + item.lubang,
      0
    );
    const newTotalRetakMelintang = historyData.reduce(
      (total, item) => total + item.retak_melintang,
      0
    );
    const newTotalRetakMemanjang = historyData.reduce(
      (total, item) => total + item.retak_memanjang,
      0
    );
    const newTotalRetakBuaya = historyData.reduce(
      (total, item) => total + item.retak_buaya,
      0
    );

    setTotalLubang(newTotalLubang);
    setTotalRetakMelintang(newTotalRetakMelintang);
    setTotalRetakMemanjang(newTotalRetakMemanjang);
    setTotalRetakBuaya(newTotalRetakBuaya);
  }, [historyData]);
  return (
    <div className="mt-5">
      <div>
        {/* Jumlah lubang card */}
        <div className="mt-10  grid-cols-2 gap-5">
          <ProjectsTable
            name={user + "!"}
          />
        </div>
      </div>
    </div>
  );
};

export default Marketplace;
