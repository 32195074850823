import React from 'react';

export const ProjectContext = React.createContext();

export const ProjectProvider = ({ children }) => {
  const [project, setProject] = React.useState(null);
  const [projectId, setProjectId] = React.useState(null);

  return (
    <ProjectContext.Provider value={{ project, setProject, projectId, setProjectId }}>
      {children}
    </ProjectContext.Provider>
  );
};