
export const columnsDataColumns = [

  {
    Header: "TANGGAL MASUK",
  },
  {
    Header: "NAMA FILE",
  },
  {
    Header: "TOTAL KERUSAKAN",
  },
  {
    Header: "VIEW DETAIL",
  },
 
];

