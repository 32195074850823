import React, { useState, useContext } from 'react';
import { ProjectContext } from "../../../utils/ProjectContext";

const ProjectDropdown = ({ options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { project, setProject } = useContext(ProjectContext);

  const handleSelect = (event) => {
    const optionId = event.target.value;
    console.log('Selected Option ID:', optionId);
    const selectedOption = options.find(option => option.id.toString() === optionId);
    console.log('Selected Option Object:', selectedOption);
    setSelectedOption(selectedOption);
    onSelect(selectedOption);
  };

  return (
    <div className="mb-4">
      <div className="relative px-[15px]">
        <select
          className="w-full py-2 px-[20px] text-gray-800 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 appearance-none"
          value={selectedOption ? selectedOption.id : ''}
          onChange={handleSelect}
        >
          <option value="">Select Project</option>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-5 flex items-center px-2 pointer-events-none">
          <svg
            className="w-5 h-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div> 
      </div>
    </div>
  );
};

export default ProjectDropdown;
