import React, { useState, useContext } from "react";
import { ProjectContext } from "../../../../utils/ProjectContext";
import axios from "axios";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const Upload = () => {
  const [file, setFile] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [files, setFiles] = useState([]);
  const [labelCounts, setLabelCounts] = useState({});
  const { project, setProject, projectId, setProjectId } = useContext(ProjectContext);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const isVideoFile = (fileName) => {
    return /\.(mp4|avi|webm)$/i.test(fileName);
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    axios
      .post(`${process.env.REACT_APP_ML_URL}/ml/upload`, formData)
      .then((res) => {
        console.log('Response:', res);
        if (res.data && res.data.url) {
          console.log('Setting uploaded image and files');
          setUploadedImage(res.data.url);
          setFiles([...files, file]);
          console.log('Displaying success message');
          Swal.fire({
            icon: "success",
            title: "Sukses!",
            text: "Gambar berhasil diunggah!",
          });
        } else {
          throw new Error("Unexpected response format");
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "success",
          title: "Sukses!",
          text: "File berhasil diunggah! Klik 'Start' untuk memproses file.",
        });
      });
  };

  const handleButtonClick = async () => {
    setProcessing(true);

    Swal.fire({
      title: "Sedang memproses file",
      html: "Harap tunggu...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("project_id", projectId);

      axios
        .post(`${process.env.REACT_APP_ML_URL}/ml/convert`, formData)
        .then((res) => {
          const data = res.data;
          console.log(data);
          setProcessing(false);

          setLabelCounts(data.label_counts);

          let labelCountsHtml = '<br>';
          for (let [label, count] of Object.entries(data.label_counts)) {
              labelCountsHtml += `<p>${label} : ${count}</p>`;
          }
          labelCountsHtml += `<p>Total : ${Object.values(data.label_counts).reduce((a, b) => a + b, 0)}</p>`;
          labelCountsHtml += '<br><p>Data telah tersimpan di database.<p>';

          const mediaElement = isVideoFile(data.output_filename) ?
            `<video controls style="width:60%; margin-left:20%; margin-right:20%;">
               <source src="${process.env.REACT_APP_ML_URL}/ml/output/${data.output_filename}" type="video/mp4">
               Your browser does not support the video tag.
             </video>` :
            `<img src="${process.env.REACT_APP_ML_URL}/ml/output/${data.output_filename}" style="width:60%; margin-left:20%; margin-right:20%;" alt="Processed Image" />`;

          Swal.fire({
            title: 'Result',
            html: `${mediaElement}${labelCountsHtml}`,
            showCloseButton: true
          }).then((result) => {
            if (result.isConfirmed) {
              axios.post(`${process.env.REACT_APP_ML_URL}/ml/store`, data)
                .then(response => {
                  console.log(response);
                  location.reload();
                })
                .catch(error => {
                  console.log(error);
                  location.reload();
                });
            }
          });
        })
        .catch((error) => {
          console.error("Error in /convert:", error);
          Swal.close();
          setProcessing(false);
        });
    } catch (error) {
      console.error("Error:", error);
      Swal.close();
      setProcessing(false);
    }
  };

  return (
    <div className="h-full w-full pb-5">
      <label
        htmlFor="dropzone-file"
        className="dark:hover:bg-bray-800 box-content flex h-full w-full flex-col   items-center  justify-center rounded-lg border-2 border-dashed  border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >
        <img
          src="/frame.png"
          alt="Icon"
          className="w-100  h-150 object-cover"
        />
        <h1 className="mt-5 mx-3 text-[20px]">
          Format yang dapat digunakan adalah JPG, PNG, dan MP4
        </h1>
        <div className="mx-12">
          <input
            className="mt-5 w-full rounded-md border border-gray-300 bg-white px-4 py-2 text-gray-800 hover:border-gray-500 focus:border-blue-300 focus:outline-none focus:ring dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200"
            type="file"
            onChange={(e) => handleFileChange(e)}
          />
        </div>
        <div className="">
          <button
            type="button"
            className="mt-4 mt-5 rounded-full bg-blue-500 px-12 py-3 text-white hover:bg-blue-700"
            onClick={handleUpload}
          >
            Upload
          </button>
        </div>
        {uploadedImage && (
          <div className="mt-4">
            <h2>Gambar Telah Diunggah:</h2>
            <img src={uploadedImage} alt="Uploaded" className="mt-2" />
          </div>
        )}

        <div>
          <button
            type="button"
            className="mt-4 mt-5 place-content-center rounded-full bg-blue-500 px-12 py-3 text-white hover:bg-blue-700"
            onClick={handleButtonClick}
          >
            Start
          </button>
          {processing && (
            <div className="modal">
              <div className="modal-content">
              </div>
            </div>
          )}
        </div>
      </label>
      <div></div>
    </div>
  );
};

export default Upload;
