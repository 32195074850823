import { useState, useEffect, useContext } from "react";
import { ProjectContext } from "../../utils/ProjectContext";

import { HiX } from "react-icons/hi";
import ProjectDropdown from './components/Dropdown';
import Links from "./components/Links";

import SidebarCard from "components/sidebar/componentsrtl/SidebarCard";
import routes from "routes.js";

const Sidebar = ({ open, onClose }) => {
  const [selectedValue, setSelectedValue] = useState();
  const [options, setOptions] = useState([]);

  const { project, setProject, projectId, setProjectId } = useContext(ProjectContext);

  const handleSelect = (selectedProject) => {
    console.log('Selected Project in Sidebar:', selectedProject);
    if (selectedProject) {
      setProject(selectedProject.name);
      setProjectId(selectedProject.id);
      setSelectedValue(selectedProject.name);
    }
  };

  const fetchProjectsByUser = async (userToken) => {
    try {
      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/projects?user=${userToken}`);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      const projects = data.map(project => ({
        name: project.name,
        id: project.id,
      }));

      if (!selectedValue && projects.length > 0) {
        setProject(projects[0].name);
        setProjectId(projects[0].id);
        setSelectedValue(projects[0].name);
      }

      setOptions(projects);
    } catch (error) {
      console.error("Error fetching history data:", error);
    }
  }

  useEffect(() => {
    try {
      const token = sessionStorage.getItem("token");
      fetchProjectsByUser(token);
    } catch (error) {
      console.error("Error fetching history data:", error);
    }
  }, []);

  return (
    <div className="bg-blue-900 fixed flex min-h-full flex-col mx-auto">
      <div className="mx-[15px] mt-[40px] flex items-center">
        <div className="mr-5 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
          <img className="ml-5 h-16 w-22" src="/logo.png" />
        </div>
      </div>
      <div className="mt-[58px] mb-7" />

      <ProjectDropdown
        options={options}
        onSelect={handleSelect}
      />

      <ul className="mb-auto pt-1">
        <Links routes={routes} />
      </ul>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
