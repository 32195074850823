import "./login.css";

import baseUrl from './config.jsx';

import React from 'react';
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import Cookies from 'js-cookie';

export const LoginPage = () => {
    const isAuthenticated = !!Cookies.get("token");
    
    const [hiddenPass, setHidden] = useState(false);
    const [errorCheck, setCheck] = useState(false);
    const [errorActive, setActive] = useState(false);
    const [loading, setLoading] = useState(false);

    const [msg, setMsg] = useState('');
    const history = useNavigate();

    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    // hidden password function state
    const changeHidden = () =>{
        setHidden(current => !current);
    }

    //authentication function to login
    const Auth = async(e) => {
        e.preventDefault();

        // activate loading animation while processing auth
        setLoading(true);

        const email = emailRef.current.value;
        const password = passwordRef.current.value;

            await axios.post(`${baseUrl}/login`,{
                email: email,
                password: password,
		        withCredentials: true
            })
            .then((response) => {
                const expirationTime = new Date(new Date().getTime() + 3600 * 1000);
                Cookies.set("token", response.data.refreshToken, {
                    expires: expirationTime,
                });
                sessionStorage.setItem("token", response.data.refreshToken);
                sessionStorage.setItem("name", response.data.name);
                sessionStorage.setItem("email", response.data.email);
                sessionStorage.setItem("userId", response.data.userId);
              
                history("/admin/default");
            })
            .catch((error) => {
                setLoading(false);

                if(error.response){
                    setMsg(error.response.data.msg);
                }

                setCheck(current=>!current);
            });
    }

    // navigate register function
    const navigateReg = () =>{
        history('/register');
    }

    // async function to set error message animation timeout
    useEffect(() => {
        
        setActive(true);

        setTimeout(() => {
            setActive(false);
        }, 500);

    }, [errorCheck]);
 
    return(
        <div className="loginPage">
            <div className="loginForm">
                <div className="leftForm">
                    {/* <h1>ROAD DAMAGE </h1> */}
                    <img src="logo.png" alt="" />
                </div>

                <div className="rightForm">
                    <img src="logo_small.png" alt="" />

                    <h3>Hello Again!</h3>
                    <p>Welcome to RoadInspecx, please fill your credential</p>
                    
                    <div className="formLogin">
            
                        <form onSubmit={Auth}>
                            <div className="formInput">
                                <input type="text" required="required" ref={emailRef}/>
                                <span className="inputName">Email</span>
                            </div>
                            <div className="formInput">
                                <input type={hiddenPass === true ? "text" : "password"} required="required" ref={passwordRef}/>
                                <span className="inputName">Password</span>
                                <span className="iconFormPass">
                                </span>
                            </div>
                            <div className="errorMsg">
                                <h4 id="errorTeks" className={errorActive ? 'activeError' : 'inactiveError'}>{msg}</h4>
                            </div>
                            
                            <div className="buttonSubmit">
                                {/* <Link to="/admin" className="backLoginReg">Login</Link> */}
                                {!loading && <button type="submit" className="subButLog">Login</button>}
                                {loading && <button type="submit" className="subButLog"><div className="loaderLogin"></div></button>}
                            </div>
                            
                        </form>

                        <h5 className="registerLink" onClick={navigateReg}>Dont have an account? Register here</h5>
                    </div>
                </div>
            </div>  
        </div>
    )
}
