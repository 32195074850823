import { columnsDataColumns } from "./variables/columnsData";
import tableDataColumns from "./variables/tableDataColumns.json";
import ColumnsTable from "./components/ColumnsTable";
import LubangDetail from "components/card/LubangDetail";
import MemanjangDetail from "components/card/MemanjangDetail";
import MelintangDetail from "components/card/MelintangDetail";
import BuayaDetail from "components/card/BuayaDetail";
import React, { useState, useEffect } from "react";
import Upload from "./components/Upload";

const Marketplace = () => {
  const [historyData, setHistoryData] = useState([]);
  const [totalLubang, setTotalLubang] = useState(0);
  const [totalRetakMelintang, setTotalRetakMelintang] = useState(0);
  const [totalRetakMemanjang, setTotalRetakMemanjang] = useState(0);
  const [totalRetakBuaya, setTotalRetakBuaya] = useState(0);

  const getQueryParam = (name) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  };

  const file = getQueryParam("file"); 
  console.log(file);
  const fetchHistoryData = async (projectId) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/history`;
    
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          project: projectId, // assuming fileName is the project id
        }),
      });
    
      const data = await response.json();
      setHistoryData(data);
    } catch (error) {
      console.error("Error fetching history data:", error);
    }
  };

  useEffect(() => {
    const newTotalLubang = historyData.reduce(
      (total, item) => total + item.lubang,
      0
    );
    const newTotalRetakMelintang = historyData.reduce(
      (total, item) => total + item.retak_melintang,
      0
    );
    const newTotalRetakMemanjang = historyData.reduce(
      (total, item) => total + item.retak_memanjang,
      0
    );
    const newTotalRetakBuaya = historyData.reduce(
      (total, item) => total + item.retak_buaya,
      0
    );

    setTotalLubang(newTotalLubang);
    setTotalRetakMelintang(newTotalRetakMelintang);
    setTotalRetakMemanjang(newTotalRetakMemanjang);
    setTotalRetakBuaya(newTotalRetakBuaya);
  }, [historyData]);
  return (
    <div className="mt-5">
      <div className="w-full mt-5"> {/* Add a margin-top for spacing */}
        <Upload />
      </div>
      <div>
        <div className="mt-10  grid-cols-2 gap-5">
          <ColumnsTable
            className="h-full w-full"
            columnsData={columnsDataColumns}
            tableData={tableDataColumns}
          />
        </div>
      </div>
    </div>
  );
};

export default Marketplace;
