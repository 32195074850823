import React, { useState, useEffect, useMemo } from 'react';
import { FaPlus, FaInfo, FaTrashAlt } from 'react-icons/fa';
import Card from 'components/card';
import './ProjectsTable.css';

const ProjectsTable = ({ name }) => {
  const [projectsData, setProjectsData] = useState([]);
  const [showAddProjectDialog, setShowAddProjectDialog] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [newProjectDescription, setNewProjectDescription] = useState('');
  const [showInfoProjectDialog, setShowInfoProjectDialog] = useState(false);
  const [project, setProject] = useState(null);
  const [animating, setAnimating] = useState(false);

  const token = sessionStorage.getItem("token");

  const fetchProjectsByUser = async (userId) => {
    try {
      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/projects?user=${userId}`);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      console.log(data);
      setProjectsData(data);
    } catch (error) {
      console.error("Error fetching projects data:", error);
    }
  };

  useEffect(() => {
    fetchProjectsByUser(token);
  }, [token]);

  const handleAddProject = () => {
    setShowAddProjectDialog(true);
  };

  const handleClose = () => {
    setShowInfoProjectDialog(false);
  };

  const handleSaveProject = async () => {
    try {
      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/project`);
      const userId = sessionStorage.getItem("userId");
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userId,
          projectName: newProjectName,
          description: newProjectDescription,
          imagePath: "https://via.placeholder.com/150",
        }),
      });
      setNewProjectName("");
      setNewProjectDescription("");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAnimating(true);
      setTimeout(() => {
        setShowAddProjectDialog(false);
        fetchProjectsByUser(token);
        setAnimating(false);
      }, 300); // Animation duration
    } catch (error) {
      console.error("Error saving project:", error);
    }
  };

  const handleInfoProject = async (projectId) => {
    try {
      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/project`);
      url.searchParams.append('id', projectId);
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setProject(data);
      setShowInfoProjectDialog(true);
    } catch (error) {
      console.error("Error fetching project info:", error);
    }
  };

  const handleDeleteProject = async (projectId) => {
    try {
      const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/project?id=${projectId}`);
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setAnimating(true);
      setTimeout(() => {
        fetchProjectsByUser(token);
        setAnimating(false);
      }, 300); // Animation duration
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  return (
    <div>
      {showAddProjectDialog && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="bg-white rounded-lg shadow-lg p-8 animate-fade-in" style={{ zIndex: 999, maxWidth: '600px', width: '90%' }}>
              <h2 className="text-xl font-bold mb-6">Add Project</h2>
              <div className="mb-6">
                <label htmlFor="projectName" className="block font-bold mb-2">Project Name</label>
                <input type="text" id="projectName" value={newProjectName} onChange={(e) => setNewProjectName(e.target.value)} className="border border-gray-300 rounded-md px-3 py-2 w-full" />
              </div>
              <div className="mb-6">
                <label htmlFor="description" className="block font-bold mb-2">Description</label>
                <textarea id="description" value={newProjectDescription} onChange={(e) => setNewProjectDescription(e.target.value)} className="border border-gray-300 rounded-md px-3 py-2 w-full"></textarea>
              </div>
              <div className="flex justify-end">
                <button className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2" onClick={() => setShowAddProjectDialog(false)}>Cancel</button>
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleSaveProject}>Save</button>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 bg-black opacity-50" style={{ zIndex: 800 }} onClick={() => setShowAddProjectDialog(false)}></div>
        </div>
      )}
      <div className={`${showAddProjectDialog ? 'blur-sm' : ''}`}>
        <Card extra={'w-full pb-10 p-4 h-full'}>
          <header className='relative flex items-center justify-between'>
            <div className='text-[30px] font-bold text-navy-700 dark:text-white'>Welcome to RoadInspecX, {name}</div>
            <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center' onClick={handleAddProject}>
              <span className='flex items-center'>
                <FaPlus className='mr-2' />
                Add Project
              </span>
            </button>
          </header>
          <div className='mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
            {projectsData.map((project, index) => (
              <div key={index} className={`bg-white rounded-lg shadow-md p-6 ${animating ? 'animate-fade' : ''}`}>
                <h3 className='text-xl font-bold mb-4'>{project.name}</h3>
                <p className='text-gray-600 mb-4'>Date Created: {project.createdAt}</p>
                <p className='text-gray-600 mb-4'>Total Data: {project.historyCount}</p>
                <p className='text-gray-600 mb-4'>Description: {project.description}</p>
                <div className='flex justify-between'>
                  <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' onClick={() => handleInfoProject(project.id)}>
                    <FaInfo />
                  </button>
                  <button className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded' onClick={() => handleDeleteProject(project.id)}>
                    <FaTrashAlt />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ProjectsTable;
