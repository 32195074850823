import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import HistoryLog from "views/admin/history";
import Damage from "views/admin/damage";
import ProjectManagement from "views/admin/project";

// Auth Imports

// Icon Imports
import {
  MdHistory,
  MdHome,
  MdMonitor,
  MdOutlineShowChart,
  MdShowChart,
  MdOutlineDashboard
} from "react-icons/md";

const routes = [
  {
    name: "Home",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <ProjectManagement />,
    secondary: true,
  },
  {
    name: "Project Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdOutlineDashboard className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Detect",
    layout: "/admin",
    path: "history",
    icon: <MdHistory className="h-6 w-6" />,
    component: <HistoryLog />,
    secondary: true,
  },


];
export default routes;
