import React from 'react';

const MapView = () => {
  const mapboxAccessToken = 'pk.eyJ1Ijoic291dGhlcm50dyIsImEiOiJjbHhqMXc3a3owd3I0MmlzZm5vbGx4bWhsIn0.lB-58YRZGO0gdJcKRU_3gQ';
  const lng = 106.827153;
  const lat = -6.175110;
  const zoom = 14;
  const mapStyle = 'mapbox/streets-v11'; // Style of the map

  const mapUrl = `https://api.mapbox.com/styles/v1/${mapStyle}/static/${lng},${lat},${zoom}/600x600?access_token=${mapboxAccessToken}`;

  return (
    <div className="flex justify-center items-center h-full">
      <img src={mapUrl} alt="Route Map" className="w-full h-full max-w-lg rounded-lg" />
    </div>
  );
};

export default MapView;
