import React, { useState, useEffect, useContext } from "react";
import { ProjectContext } from "../../../utils/ProjectContext";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import TotalCard from "components/card/TotalCard";
import RetakCard from "components/card/RetakCard";
import LubangCard from "components/card/LubangCard";
import BuayaCard from "components/card/BuayaCard";
import MelintangCard from "components/card/MelintangCard";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import MapView from "./components/MapView";  // Import the MapView component

const Dashboard = () => {
  const [historyData, setHistoryData] = useState([]);
  const [totalLubang, setTotalLubang] = useState(0);
  const [totalRetakMelintang, setTotalRetakMelintang] = useState(0);
  const [totalRetakMemanjang, setTotalRetakMemanjang] = useState(0);
  const [totalRetakBuaya, setTotalRetakBuaya] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);

  // Context
  const { project } = useContext(ProjectContext);

  // Authentication stuff
  const isAuthenticated = !!Cookies.get("token");

  const fetchHistoryData = async (projectName) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/history?project=${projectName}`;
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);
      setHistoryData(data);
    } catch (error) {
      console.error("Error fetching history data:", error);
    } finally {
      setDataLoaded(true);
    }
  };

  useEffect(() => {
    const isFirstVisit = localStorage.getItem("isFirstVisit");
    localStorage.setItem("isFirstVisit", "true");
  }, []);

  useEffect(() => {
    const role = sessionStorage.getItem("name");
    console.log(role);
    fetchHistoryData(project);
  }, [project]);

  useEffect(() => {
    const newTotalLubang = historyData.reduce((total, item) => total + item.lubang, 0);
    const newTotalRetakMelintang = historyData.reduce((total, item) => total + item.retak_melintang, 0);
    const newTotalRetakMemanjang = historyData.reduce((total, item) => total + item.retak_memanjang, 0);
    const newTotalRetakBuaya = historyData.reduce((total, item) => total + item.retak_buaya, 0);

    setTotalLubang(newTotalLubang);
    setTotalRetakMelintang(newTotalRetakMelintang);
    setTotalRetakMemanjang(newTotalRetakMemanjang);
    setTotalRetakBuaya(newTotalRetakBuaya);
  }, [historyData]);

  const totalSemua = totalLubang + totalRetakMemanjang + totalRetakBuaya + totalRetakMelintang;

  return (
    <div className="mt-10">
      <div className="grid gap-10 md:grid-cols-5">
        <TotalCard title="Total Kerusakan" value={totalSemua} />
        <LubangCard title="Lubang" value={totalLubang} />
        <MelintangCard title="Retak Melintang" value={totalRetakMelintang} />
        <RetakCard title="Retak Memanjang" value={totalRetakMemanjang} />
        <BuayaCard title="Retak Buaya" value={totalRetakBuaya} />
      </div>
      <div className="mt-10 grid grid-cols-1 gap-5 rounded-2xl md:grid-cols-2">
        {dataLoaded && (
          <DailyTraffic
            totalLubang={totalLubang}
            totalRetakMelintang={totalRetakMelintang}
            totalRetakMemanjang={totalRetakMemanjang}
            totalRetakBuaya={totalRetakBuaya}
          />
        )}
        <MapView /> {/* Add the MapView component */}
      </div>
    </div>
  );
};

export default Dashboard;
