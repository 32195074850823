import React, { useState, useEffect, useMemo, useContext } from 'react';
import { ProjectContext } from "../../../../utils/ProjectContext";
import axios from 'axios';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import CardMenu from 'components/card/CardMenu';
import Card from 'components/card';
import Swal from 'sweetalert2';

const ColumnsTable = (props) => {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const { project, setProject } = useContext(ProjectContext);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;

  const [historyData, setHistoryData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedNamaFile, setSelectedNamaFile] = useState('');

  useEffect(() => {
    const fetchHistoryData = async (projectName) => {
      try {
        const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/history-log?project=${projectName}`);

        const response = await fetch(url, {
          method: 'GET', // Change to GET if you're using query parameters
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        
        setHistoryData(data);
      } catch (error) {
        console.error("Error fetching history data:", error);
      }
    }

    fetchHistoryData(project);
  }, [project]);

  const isVideoFile = (fileName) => {
    return /\.(mp4|avi|webm)$/i.test(fileName);
  };

  return (
    <Card extra={'w-full pb-10 p-4 h-full'}>
      <header className='relative flex items-center justify-between'>
        <div className='text-[30px] font-bold text-navy-700 dark:text-white'>
          Overview
        </div>
        <CardMenu />
      </header>

      <div className='mt-8 overflow-x-scroll xl:overflow-x-hidden'>
        <table {...getTableProps()} className='w-full'>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className='border-b border-gray-200 pb-[10px] pr-14 text-start dark:!border-navy-700'
                  >
                    <div className='flex w-full justify-between pr-10 text-xs tracking-wide text-gray-600'>
                      {column.render('Header')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {historyData.map((history) => (
              <tr className='font-bold ' key={history.id}>
                <td>{history.tanggal_masuk}</td>
                <td>{history.nama_file}</td>
                <td>{history.total_kerusakan}</td>
                <td>
                  <a
                    style={{ color: '#164386', textDecoration: 'underline' }}
                    onMouseOver={(e) => (e.currentTarget.style.color = 'red')}
                    onMouseOut={(e) => (e.currentTarget.style.color = '#164386')}
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedNamaFile(history.nama_file);
                      let before_detection_fileName = history.nama_file.replace('output_', '');
                      setSelectedNamaFile(before_detection_fileName);
                      console.log(before_detection_fileName);

                      const processedMediaElement = isVideoFile(history.nama_file) ? 
                      `<video controls style="width:500px;">
                        <source src="${process.env.REACT_APP_ML_URL}/ml/output/${history.nama_file}" type="video/mp4">
                        Your browser does not support the video tag.
                      </video>` :
                      `<img src="${process.env.REACT_APP_ML_URL}/ml/output/${history.nama_file}" style="width:500px;" alt="Processed Image">`;

                      const beforeMediaElement = isVideoFile(before_detection_fileName) ? '' :
                      `<div class="before-image">
                        <p>Before Detection</p>
                        <img src="${process.env.REACT_APP_ML_URL}/ml/Dataset1/test1/${before_detection_fileName}" style="width:500px;" alt="Unprocessed Image">
                      </div>`;

                      Swal.fire({
                      title: 'Result',
                      html: `
                        <style>
                        .image-container {
                            display: flex;
                            justify-content: space-between;
                            margin: 20px;
                        }
                        .image-container img, .image-container video {
                            width: 45%;
                            border: 1px solid #ccc;
                            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
                        }
                        </style>
                        <div class="image-container">
                          ${beforeMediaElement}
                          <div class="after-image">
                            <p>After Detection</p>
                            ${processedMediaElement}
                          </div>
                        </div>
                        <br>
                        <p>Lubang : ${history.lubang}</p>
                        <p>Retak Melintang : ${history.retak_melintang}</p>
                        <p>Retak Memanjang : ${history.retak_memanjang}</p>
                        <p>Retak Buaya : ${history.retak_buaya}</p>
                        <p>Total Kerusakan : ${history.total_kerusakan}</p>
                        <br><p>Created At : ${history.tanggal_masuk}</p>`,
                      confirmButtonText: 'Close',
                      });
                    }}
                  >
                    View Detail
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </Card>
  );
};

export default ColumnsTable;
